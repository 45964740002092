import styled from 'styled-components';

import { SvgContainer } from "../FeaturedChallenges/styles";

const MediumFont = 'Rubik-Regular';
const BoldFont = 'Rubik-Medium';

const ChallengeDashboardContainer = styled.div`
  width: 100%;
  max-width: 1246px;
    margin: auto;
`;
const ChallengeSvgContainer = styled(SvgContainer)`
  margin: 5px 0;
  > svg {
    margin-right: 5px;
  }
  
  > span {
    font-size: 14px;
  }

  > img {
    width: ${({width}) => width ? width : '20px'};
    margin-right: 6px;
  }
`;

const ButtonContainer = styled.div`
  float: left;
  width: 100%;
  display: flex;
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

const CreateButton = styled.button`
  width: 385px;
  height: 50px;
  margin-top: 39px;
  background: linear-gradient(90deg, rgb(0, 111, 188) -1.11%, rgb(127, 67, 255) 97.46%);
  border: none;
  color: white;
  white-space: nowrap;
  padding: 10px 30px;
  font-size: 13px;
  border-radius: 4px;
  text-transform: uppercase;
  font-family: ${BoldFont};
  float: right;
  letter-spacing: 1.6px;
  &:focus, &:active, &:hover {
    outline: none;
  }
`;

const Layout = styled.div`
  padding: ${({padding}) => padding ? '20px 0px 0px 30px' : '0'};
  width: 100%;
  float: left;
  height: auto;
.first{
  width: 100%;
  .siglneCardmain{
    width: 100%;
    display: flex;
    /* flex-wrap: wrap; */
    gap:25px;
  }
  .singleCardmainFeature{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap:25px;
  }
}

.recommended_class {
width: 100%;
/* margin-top: 60px; */
display: flex;
flex-wrap: wrap;
cursor: pointer;
}

.recommended_wellness_class {
width: 100%;
display: flex;
/* margin-top: 60px; */
flex-wrap: wrap;
cursor: pointer;
}

  @media (max-width: 1235px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    padding: 30px 15px 20px;
  }
  
`;
const ChallengesContainer = styled.div`
  float: ${({noFloat}) => noFloat ? 'none' : 'left'};
  width: 100%;
  padding: ${({noPadding}) => noPadding ? '0px' : '7px 8px 0'};
`;

const TopChallenges = styled.div`
  float: left;
  min-height: 160px;
  width: 100%;
  display: flex;
  text-align: left;
  position: relative;
  @media(max-width: 660px) {
    flex-direction: column;
    height: auto;
  }
`;

const TopChallengesImage = styled.div`
  width: 30%;
  margin-right: 10px;
  display: inline-block;
  float: left;
  cursor: pointer;
  position: relative;
  .bg-opacity {
    position: absolute;
    height: 160px;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.329545);
    border-radius: 6px;
    @media (max-width: 660px) {
      height: 210px;
    }
  }
  > img {
    border-radius: 6px;
    width: 100%;
    height: 160px;
  }
  .event-type{
    position: absolute;
    top: 15px;
    right: 12px;
  }
  @media (max-width: 660px) {
    width: 100%;
    margin-right: 0;
    height: 210px;
    > img {
      height: 100%;
    }
  }
`;

const TopChallengeContent = styled.div`
  width: 35%;
  font-size: 13px;
  display: inline-block;
  float: left;
  &:last-child{
    padding-right: 8px;
  }
  @media (max-width: 660px) {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    width: 100%;
    float: none;
    padding-left: 15px;
  }
  .challenge {
    height: 50%;
    padding-top: 7px;
    .title{
      font-size: 15px;
      font-family: ${BoldFont};
      width: 90%;
      text-transform: capitalize;
      overflow: hidden;
      color: #67676A;
      @media (max-width: 500px) {
        font-size: 16px;
      }
    }
  }

  .challenge-details {
    float: left;
    font-size: 12px;
    font-weight: normal;
    text-transform: capitalize;
    color: #67676A;
    font-family: ${BoldFont};
    margin-left: 0px;
    @media (max-width: 660px) {
      position: static;
      margin-top: 10px;
    }
  }
  .data {
    line-height: 14px;
    padding-top: 5px;
    margin-left: 0;
    > span {
      color: #67676A;
      font-family: ${MediumFont};
    }
  }

  .challenge-remaining {
    font-size: 12px;
    font-weight: normal;
    text-transform: capitalize;
    bottom: 15px;
    color: #1E76AB;
    margin-left: 0px;
    @media (max-width: 660px) {
      position: static;
      margin-top: 10px;
    }
  }

  .challenge-text {
    text-transform: none;
    margin-left: 0;
    padding-top: 50px;
    @media (max-width: 660px) {
      padding-top: 10px;
    }
    > span {
      color: #1E76AB;
      font-family: ${MediumFont};
    }
  }
  .challenge-info {
    float: left;
    font-size: 13px;
    padding-top: 7px;
    font-family: ${BoldFont};
    color: #67676A;
    text-transform: none;
    @media (max-width: 660px) {
      position: static;
      margin: unset;
      padding: unset;
    }
  }
  .info-text {
    margin-left: 0;
    float: left;
    width: 100%;
    max-height: 75px;
    padding-top: 5px;
    font-family: ${MediumFont};
    color: #67676A;
    font-size: 12px;
    text-transform: none;
    -webkit-line-clamp: 4;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box !important;
    overflow: hidden;
    @media (max-width: 660px) {
      position: static;
      margin: unset;
      padding: unset;
    }
  }
  .challenge-user {
    float: left;
    text-transform: capitalize;
    bottom: 5px;
    position: absolute;
    margin-left: 0px;
    @media (max-width: 660px) {
      position: static;
      margin: 10px 15px;
    }
  }
  .user-info {
    display: flex;
    margin-left: 0;
    > h6 {
      color: #67676A;
      font-size: 15px
      font-family: ${MediumFont};
      opacity: 0.5
      margin-left: 5px;
      text-transform: none;
      margin-top: 0;
    }
    > span {
      margin-left: -12px;
      > img {
        width:30px;
        height: 30px;
        border-radius: 50%;
        border: 2px solid #fff;
      }
    }
    .user-list {
      color: #67676A;
      font-size: 13px;
      font-family: ${MediumFont};
      opacity: 0.5
      margin-left: 5px;
      text-transform: none;
      margin-top: 0;
      display: flex;
      justify-content: center;
      align-items: center
    }
    .circle {
      font-family: ${MediumFont}
      font-size: 11px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 2px solid #fff;
      text-align: center;
      background-color: #4798D9;
    }
  }
`;
const CommonContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f7f5eb;
  float: left;
  margin-bottom: 20px;
  border-radius: 6px;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  @media (max-width: 760px) {
    float: left;
  > div {
    > span {
      > img {
        height: 25px;
        width: 25px;
        margin-right: 10px;
      }
    }
  }
}
`;
const ChallengeHeader = styled.div`
  color: ${({color,newColor, colorValue}) =>colorValue?colorValue: color ? '#0D4270' : newColor ? '002f47' : '#484856'};
  font-size: ${({color,newColor}) => color ? '18px' : newColor ? '18px' : '18px'};
  line-height: ${({color,newColor}) => color ? '24px' : newColor ? '21px' : '14px'};
  font-family: ${BoldFont};
  margin: ${({color,newColor}) => color ? '0px 0px 25px 0px' : newColor ? '0px 0px 25px 0px' : '0 0 20px'};
  width: 100%;
  /* white-space: nowrap; */
  text-overflow: clip;
  font-weight: 500;
`;


const SliderContainer = styled.div`
  width: 100%;

  .slick-slider {
    width: 100%;
    margin: ${({marginValue}) => marginValue ? marginValue : '20px 0 10px'};
    padding: ${({padding}) => padding ? padding : ''};
    box-shadow: ${({ shadow }) => (shadow ? shadow : "")};
    .slick-slide{
      margin: 0px;
      display: flex;
      justify-content: center;
      display: ${({display}) => display ? display : 'block'};
      justify-content: ${({content}) => content ? content : ''};
      margin: ${({margin}) => margin ? margin : ''};
      @media (max-width: 600px){
        margin: 0 auto;
      }
    }
    .slick-prev {
      left: -20px;
      position: absolute;
      top: 50%;
      right: -52px;
      z-index: 1;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      background-color: #005c87;
      color:white;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 1.375rem;
    }
    .slick-prev:before {
      font-family: "Rubik";
      font-size: 42px;
      line-height: 1;
      color: white;
      margin: 0 15px;
      opacity: 0.75;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      transition: left .3s ease;
      }
      .slick-prev:before {
        content: "‹";
      }
      [dir="rtl"] .slick-prev:before {
        content: "›";
      }
    .slick-next {
        left: unset;
        position: absolute;
        top: 50%;
        right: -15px;
        z-index: 1;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background-color: #005c87;
        color:white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 1.375rem;
      }
      .slick-next:before {
        content: "›";
        font-family: "Rubik";
        font-size: 42px;
        line-height: 1;
        color: white;
        margin: 0 18px;
      }
      [dir="rtl"] .slick-next:before {
        content: "‹";
      }
  }
`;
const ChallengeBody = styled.div`
  width: 100%;
  position: absolute;
  bottom: ${({bottom}) => bottom ? '45px' : '30px'};
  color: #484856;
  font-size: 14px;
  font-family: ${MediumFont}
  margin-left: 0;
  float: left;
  max-height: 92px;
  padding-top: 5px;
  text-transform: none;
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre;
  > div {
    text-align: center;
  }
`;
const SeeAllButton = styled.div`
  font-family: ${MediumFont};
  new-width: ${({width}) => width ? '75px' : '100%'};
  max-width: ${({width}) => width ? '100px' : '100%'};;
  background: ${({width}) => width ? '#dddbdb' : 'transparent'};
  font-size: 18px;
  text-decoration: underline;
  color: ${({color}) => color ? '#005C8799' : '#000000'};
  cursor: pointer;
  padding: ${({width}) => width ? '5px' : '0px'};
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: ${({width}) => width ? 'center' : 'flex-end'};
  > img{
    margin-right: 5px;
  }
`;
const ChallengeSideLayout = styled.div`
  float: left;
  height: auto;
  margin-top: 60px;
  margin-bottom: 10px;
  display: flex;
  gap: 25px;
  width: 100%;
  /* padding-left: 15px; */
  
  .first{
    width: 100%;
    .siglneCardmain{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
  }

  @media (max-width: 1235px) {
    width: 100%;
  } 
`;
const NoData = styled.span`
  font-size: 15px;
  font-family: ${BoldFont};
  text-align: center;
  width: 100%;
  display: block;
  margin-top: 10px;
  float: left;
  color: #484856;
  padding: 25px 10px;
  background: #FFFFFF;
`;

const DivContainer = styled.div`
  width: 398px;
  height: 376px;
  margin-bottom: 25px;
  padding-top: ${({padding}) => padding ? padding : ''};
`;

const ChallengeDashboardContainermain = styled.div`
width: 100%;
padding: 0px 25px;
.slick-height .slick-list{
    height: 315px !important;
    border-radius: 6px !important;
  }
`;

const NewConnectDevice = styled.div`
width: 398px;
margin-bottom:${({margin})=> margin ? '25px' : '25px'};
padding-top:${({margin})=> margin ? 'none' : '0px'};
padding-bottom:${({margin})=> margin ? 'none' : '1px'};
background-color: white;
box-shadow: 0px 8px 25px 0px #005C8740;
border-radius:6px;
> button {
  width: 100%;
  height: 65px;
  font-family: ${BoldFont};
  font-size: 18px;
  padding:0px 25px;
  border: none;
  border-radius: 0px 0px 6px 6px;
  outline: none;
  margin-bottom: 18px;
  margin-top: 15px;
  background-color: white;
  > div{
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({bgColor}) => bgColor ? '#005c87' : '#005c87'};
  color: ${({color}) => color ? '#0D4270;' : '#fff'};
  border-radius: 6px;
  box-shadow: 0px 8px 24px 0px #005C8780;

    width: 100%;
    > span {
      padding-top: 10px;
      font-family: Rubik-Medium;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
    }
  }
}
@media(max-width: 766px){
  > button {
    > img {
      width: 8%;
    }
    div{
      > img {
      width: 8%;
    }

    }
    > div {
      justify-content: center;
      align-items: center;
      padding-left: 0%;
    }
  }
}
@media(max-width: 500px){
  > button {
    > img {
      width: 12%;
    }
    div{
      > img {
      width: 12%;
    }

    }
    > div {
      justify-content: center;
      align-items: center;
      padding-right: 25%;
      padding-left: 14%;
    }
  }
}
`;

const DeviceWrapper = styled.div`
  /* height:110px; */
  margin:0px 10px;
  display:flex;
  border-bottom: 1px solid #b0ccd7; 
`

const DeviceContent = styled.div`
  height:110px;
  margin:0px 10px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  & > p:first-child{
    font-size : 16px;
    font-weight: 600;
    line-height: 20px;
    color:#005c87;
    /* font-family:'rubik-medium'; */
  }  
  & >p:last-child{
    font-size : 16px;
    font-weight: 400;
    color:#76ab78;
  }
  
  `

const  NewCreateButton = styled.div`
width: 100%;
margin-bottom:${({margin})=> margin ? '25px' : '0px'};
padding-bottom:${({margin})=> margin ? 'none' : '3px'};
> button {
  width: 100%;
  height: 100%;
  font-family: ${MediumFont};
  font-size: 15px;
  background: linear-gradient(90deg, rgb(0, 111, 188) -1.11%, rgb(127, 67, 255) 97.46%);
  color: #FFF;
  padding: 3px;
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: stretch;
  outline: none;
  margin-bottom: 18px;
  div{
    > img {
    width: 44px;
    height: 44px;
    margin-right: 0px;
    float: left;
  }

  }

  > div{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    > span {
      padding-top: 10px;
      font-family: Rubik-Medium;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
    }
  }
}
@media(max-width: 766px){
  > button {
    > img {
      width: 8%;
    }
    div{
    > img {
    width: 8%;
  }

  }
    > div {
      justify-content: center;
      align-items: center;
      padding-left: 0%;
    }
  }
}
@media(max-width: 500px){
  > button {
    > img {
      width: 12%;
    }
    div{
    > img {
    width: 12%;
  }

  }
    > div {
      justify-content: center;
      align-items: center;
      padding-right: 25%;
      padding-left: 14%;
    }
  }
}
`;

const ChallengeHeadermain = styled.div`
  width: 98.5%;
  display: flex;
  & > div:first-child {
    width: 50%;
    }
    & > div:last-child {
      width: 50%;
      }
`;


const NewChallengeHeader = styled.div`
  color: ${({color,newColor}) => color ? '#005c87' : newColor ? '002f47' : '#484856'};
  font-size: ${({color,newColor}) => color ? '18px' : newColor ? '18px' : '15px'};
  line-height: ${({color,newColor}) => color ? '24px' : newColor ? '21px' : '14px'};
  font-family: ${BoldFont};
  margin: ${({color,newColor}) => color ? '0px 0px 25px 0px' : newColor ? '0px 0px 25px 0px' : '25px 0 20px'};
  width: 98.5%;
  white-space: nowrap;
  text-overflow: clip;
`;

const FitnessGoalWrapper = styled.div`
      width: 398px;
      height: 226px;
      margin-bottom: 25px;
      margin-top: 10px;
      & > p{
        font-size: 16px;
        font-weight: 500;
        font-family: Rubik-medium;
        color:#005c87 !important;
        line-height: 24px;
        margin-bottom: 20px;
      }
`
const ChallengeInvitationWrapper = styled.div`
      width: 398px;
      margin-bottom: 25px;
      & > p{
        font-size: 18px;
        color:#005c87;
        margin-bottom: 25px;
        margin-top: -5px;
        font-family: rubik-medium;
      }
`
const InvitationCardWrapper = styled.div`
      background-color: white;
      border-radius: 6px;
      padding: 15px;
      box-shadow: 0px 8px 25px 0px #0D427040;
`
const InvitationButtonWrapper = styled.div`
      display: flex;
      gap:20px;
      margin: 15px 0px 0px 0px;
      & > button:first-child {
        height: 40px;
        border-radius:3px ;
        background-color: #005C87;
        box-shadow: 0px 8px 24px 0px #005C8780;
        color:white;
        padding:0px 65px;
        border:none;
        font-weight: 500;
        font-size: 14px;
        font-family: rubik-medium;
      }
      & > button:last-child {
        height: 40px;
        border-radius:3px ;
        color:#669db7;
        padding:0px 65px;
        background-color: white;
        border:1px solid #669db7;
        font-weight: 500;
        font-size: 14px;
        font-family: rubik-medium;
      }
`



export {ChallengeInvitationWrapper,InvitationCardWrapper,InvitationButtonWrapper, FitnessGoalWrapper,ChallengeSvgContainer,DeviceWrapper, DeviceContent, ButtonContainer, CreateButton, Layout, TopChallengeContent, ChallengesContainer, TopChallenges, CommonContainer, TopChallengesImage, ChallengeHeader, SliderContainer, ChallengeBody, SeeAllButton, ChallengeSideLayout, ChallengeDashboardContainer, NoData, DivContainer, ChallengeDashboardContainermain,NewConnectDevice,NewCreateButton, ChallengeHeadermain,NewChallengeHeader};